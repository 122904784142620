import React from "react";
import "./PxInput.css";

interface PxInputProps {
    label: string;
    type?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    name?: string;
    placeholder?: string;
    errorMessage?: string;
    isError?: boolean;
    style?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
}

export default function PxInput({
    label,
    type = "text",
    value,
    onChange,
    name,
    placeholder = "",
    errorMessage,
    isError = false,
    style = {},
    containerStyle = {},
}: PxInputProps) {
    return (
        <div className="input-container" style={containerStyle}>
            <input
                id={name}
                type={type}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={isError ? "input-error" : ""}
                style={style}
                onFocus={(e) => {
                    const border = e.target.nextSibling as HTMLDivElement;
                    if (border) {
                        border.style.backgroundColor = "#4B45FF";
                    }
                }}
                onBlur={(e) => {
                    const border = e.target.nextSibling as HTMLDivElement;
                    if (border) {
                        border.style.backgroundColor = isError
                            ? "red"
                            : "black";
                    }
                }}
            />
            <div
                className="input-border"
                style={{
                    backgroundColor: isError ? "red" : "black",
                }}
            />
            <label
                htmlFor={name}
                className={value || placeholder ? "filled" : ""}>
                {label}
            </label>

            {isError && errorMessage && (
                <span className="error-message">{errorMessage}</span>
            )}
        </div>
    );
}
