import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthHandler from "./Screens/Auth/AuthHandler";
import PrivacyScreen from "./Screens/Docs/PrivacyScreen";
import TermsScreen from "./Screens/Docs/TermsScreen";
import RtroPage from "./Screens/RtroPage";
import GuidelinesScreen from "./Screens/Docs/GuidelinesScreen";
import FeesScreen from "./Screens/Docs/FeesScreen";
import DisputesScreen from "./Screens/Docs/DisputesScreen";
import UploadModelImagesScreen from "./Screens/Forms/UploadModelImagesScreen";
import ProtectedRoute from "./Services/ProtectedRoute";
import LogoutScreen from "./Screens/Auth/LogoutScreen";
import AuthProvider from "./Providers/AuthProvider";
import "antd/dist/reset.css";
import Error404Screen from "./Screens/Error404";
import { useMiscStore } from "./state/store";
import ErrorLogScreen from "./Screens/Admin/Pages/Errors/ErrorLog";
import AdminDashboardContainer from "./Screens/Admin/AdminDashboardContainer";
import BotManagementScreen from "./Screens/Admin/Pages/BotManagement";
import WarehouseScreen from "./Screens/Admin/Pages/Warehouse";
import UsersScreen from "./Screens/Admin/Pages/Users";
import AnalyticsPage from "./Screens/Admin/Pages/Analytics";
import TradesScreen from "./Screens/Admin/Pages/Trades";
import ReferralScreen from "./Screens/Referrals/ReferralsScreen";
import ErrorView from "./Screens/Admin/Pages/Errors/ErrorView";
import UnsubscribeScreen from "./Screens/UnsubscribeScreen";
import UserProfile from "./Screens/Admin/Pages/Profile/Profile";
import OurHistoryScreen from "./Screens/OurHistory/OurHistoryScreen";
import FAQScreen from "./Screens/FAQ/FAQScreen";
import ContactUsScreen from "./Screens/ContactUsScreen";
import LandingScreen from "./Screens/LandingScreen/LandingScreen";
import AuthenticationInfoScreen from "./Screens/AuthenticationInfo/AuthenticationInfoScreen";

function App() {
    const Router = BrowserRouter;
    useMiscStore((state) => state.theme);

    return (
        <>
            <AuthProvider />
            <Router>
                <Routes>
                    {/* 404 Not Found */}
                    <Route
                        path="*"
                        element={
                            <RtroPage>
                                <Error404Screen />
                            </RtroPage>
                        }
                    />

                    {/* Landing page */}
                    <Route
                        path="/"
                        element={
                            <RtroPage>
                                <LandingScreen />
                            </RtroPage>
                        }
                    />

                    {/* Authentication routes */}
                    <Route
                        path="/auth"
                        element={
                            <RtroPage>
                                <AuthHandler />
                            </RtroPage>
                        }
                    />

                    <Route
                        path="/logout"
                        element={
                            <ProtectedRoute>
                                <LogoutScreen />
                            </ProtectedRoute>
                        }
                    />

                    {/* Authenticated routes - admin */}
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute>
                                <AdminDashboardContainer />
                            </ProtectedRoute>
                        }>
                        <Route path="trades" element={<TradesScreen />} />
                        <Route path="analytics" element={<AnalyticsPage />} />
                        <Route path="users" element={<UsersScreen />} />
                        <Route path="users/:id" element={<UserProfile />} />
                        <Route path="warehouse" element={<WarehouseScreen />} />
                        <Route path="bot" element={<BotManagementScreen />} />
                        <Route path="errors" element={<ErrorLogScreen />}>
                            <Route path=":id" element={<ErrorView />} />
                        </Route>
                    </Route>

                    {/* Authenticated routes - non-admin */}
                    <Route
                        path="/upload"
                        element={
                            <ProtectedRoute>
                                <RtroPage>
                                    <UploadModelImagesScreen />
                                </RtroPage>
                            </ProtectedRoute>
                        }
                    />

                    {/* Non-authenticated static routes  */}
                    <Route
                        path="/ul/referral/:referralSlug"
                        element={<ReferralScreen />}
                    />
                    <Route
                        path="/ul/*"
                        element={<ReferralScreen hideReferral />}
                    />
                    <Route
                        path="/privacy"
                        element={
                            <RtroPage>
                                <PrivacyScreen />
                            </RtroPage>
                        }
                    />
                    <Route
                        path="/terms"
                        element={
                            <RtroPage>
                                <TermsScreen />
                            </RtroPage>
                        }
                    />
                    <Route
                        path="/guidelines"
                        element={
                            <RtroPage>
                                <GuidelinesScreen />
                            </RtroPage>
                        }
                    />
                    <Route
                        path="/fees"
                        element={
                            <RtroPage>
                                <FeesScreen />
                            </RtroPage>
                        }
                    />
                    <Route
                        path="/disputes"
                        element={
                            <RtroPage>
                                <DisputesScreen />
                            </RtroPage>
                        }
                    />
                    <Route
                        path="/history"
                        element={
                            <RtroPage>
                                <OurHistoryScreen />
                            </RtroPage>
                        }
                    />
                    <Route
                        path="/authentication"
                        element={
                            <RtroPage fullWidth>
                                <AuthenticationInfoScreen />
                            </RtroPage>
                        }
                    />
                    <Route
                        path="/faq"
                        element={
                            <RtroPage>
                                <FAQScreen />
                            </RtroPage>
                        }
                    />
                    <Route
                        path="/contact"
                        element={
                            <RtroPage>
                                <ContactUsScreen />
                            </RtroPage>
                        }
                    />
                    <Route
                        path="/unsubscribe"
                        element={
                            <RtroPage>
                                <UnsubscribeScreen />
                            </RtroPage>
                        }
                    />
                </Routes>
            </Router>
        </>
    );
}

export default App;
