import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useAuth } from "../../Hooks/useAuth";
import { useMiscStore } from "../../state/store";
import { colors } from "../../Utils/colors";
import PxButton from "../../Components/PxButton";
import SignUpFlow from "../../Components/SignUpFlow";
import {
    RiAppleFill,
    RiArrowRightFill,
    RiArrowRightLine,
    RiCheckFill,
    RiGoogleFill,
    RiMailLine,
} from "react-icons/ri";
import { appleAuthHelpers } from "react-apple-signin-auth";
import PxInput from "../../Components/PxInput";

const rtro_logo = require("../../images/rtro-logo-new-light.png");
const bg = require("../../images/loginbg.webp");

export default function LoginModal({
    toggleLoginModal,
}: {
    toggleLoginModal: () => void;
}) {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const {
        containerStyle,
        titleStyle,
        termsStyle,
        footerStyle,
        authLinkStyle,
    } = styles(colors, getThemeColor);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [showSignUpFlow, setShowSignUpFlow] = useState(false);

    const { logInWithEmailAndPassword, signInWithGoogle } = useAuth();

    const loginWithEmailPass = async () => {
        setLoading(true);
        setError("");
        if (!email.includes("@") || password.length < 1) {
            setError("Please enter valid credentials");
            return;
        }

        const res = await logInWithEmailAndPassword(email, password);
        console.log("res", res);
        if (res.status === "success") {
            setLoading(false);
            toggleLoginModal();
        } else if (res.status === "error") {
            setError(res.message);
            setLoading(false);
        }
    };

    function handleShowLoginForm() {
        setShowSignUpFlow(false);
        setShowLoginForm(!showLoginForm);
    }

    function handleStartSignUpFlow() {
        setShowLoginForm(false);
        setShowSignUpFlow(true);
    }

    const handleCompleteSignUp = () => {
        toggleLoginModal();
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", height: "650px" }}>
            <div
                style={{
                    ...containerStyle,
                    ...{
                        width: showSignUpFlow ? "100%" : "50%",
                    },
                }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                    }}>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                        }}>
                        {!showSignUpFlow && (
                            <div>
                                <img
                                    src={rtro_logo}
                                    alt="RTRO logo"
                                    style={{
                                        width: "140px",
                                    }}
                                />
                                <p style={titleStyle}>Sneakers & Streetwear</p>
                            </div>
                        )}

                        <AnimatePresence mode="wait">
                            {/* Show either the initial “buttons”, the login form, or the sign-up flow */}

                            {/* 1) INITIAL BUTTONS (only show if we're not in login or sign-up flow) */}
                            {!showLoginForm && !showSignUpFlow && (
                                <motion.div
                                    key="initialButtons"
                                    initial={{ x: 0, opacity: 1 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: -200, opacity: 0 }}
                                    transition={{ duration: 0.5 }}>
                                    <PxButton
                                        label="SIGN-UP WITH E-MAIL"
                                        icon={
                                            <RiMailLine
                                                size={20}
                                                className="my-icon"
                                            />
                                        }
                                        variant="primary"
                                        onClick={handleStartSignUpFlow}
                                    />
                                    <PxButton
                                        label="APPLE"
                                        icon={
                                            <RiAppleFill
                                                size={20}
                                                className="my-icon"
                                            />
                                        }
                                        variant="outline"
                                        onClick={async () => {
                                            // In real usage, you'd sign in with Google
                                            // but also want to keep sign-up flow?
                                            // For demonstration, we’ll just open sign-up flow:
                                            const authOptions = {
                                                /** Client ID - eg: 'com.example.com' */
                                                clientId: "com.rtro",
                                                /** Requested scopes, seperated by spaces - eg: 'email name' */
                                                scope: "email name",
                                                /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                                                redirectURI: "https://rtro.com",
                                                /** State string that is returned with the apple response */
                                                state: "state",
                                                /** Nonce */
                                                nonce: "nonce",
                                                /** Uses popup auth instead of redirection */
                                                usePopup: false,
                                            };
                                            const response =
                                                await appleAuthHelpers.signIn({
                                                    authOptions,
                                                    onError: (error: any) =>
                                                        console.error(error),
                                                });
                                            // if you wanted to redirect or handle an error, do it here
                                            setShowSignUpFlow(true);
                                        }}
                                    />
                                    <PxButton
                                        label="GOOGLE"
                                        icon={
                                            <RiGoogleFill
                                                size={20}
                                                className="my-icon"
                                            />
                                        }
                                        variant="outline"
                                        onClick={async () => {
                                            // In real usage, you'd sign in with Google
                                            // but also want to keep sign-up flow?
                                            // For demonstration, we’ll just open sign-up flow:
                                            await signInWithGoogle();
                                            // if you wanted to redirect or handle an error, do it here
                                            setShowSignUpFlow(true);
                                        }}
                                    />
                                </motion.div>
                            )}

                            {/* 2) SHOW LOGIN FORM */}
                            {showLoginForm && (
                                <motion.div
                                    key="loginForm"
                                    initial={{ x: 200, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: 200, opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                    style={{
                                        marginTop: "20px",
                                    }}>
                                    {error && (
                                        <p
                                            style={{
                                                color: "red",
                                                fontSize: 12,
                                                marginBottom: 10,
                                            }}>
                                            {error}
                                        </p>
                                    )}

                                    <PxInput
                                        label="Email"
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />

                                    <PxInput
                                        label="Password"
                                        type={"password"}
                                        placeholder="Enter your password"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                    <div
                                        style={{
                                            paddingTop: 40,
                                        }}>
                                        <PxButton
                                            label="Log In"
                                            variant="primary"
                                            onClick={loginWithEmailPass}
                                            loading={loading}
                                            icon={
                                                <RiArrowRightLine
                                                    size={20}
                                                    className="my-icon"
                                                />
                                            }
                                        />
                                    </div>
                                </motion.div>
                            )}

                            {/* 3) SHOW SIGNUP FLOW */}
                            {showSignUpFlow && (
                                <motion.div
                                    key="signupFlow"
                                    initial={{ x: 200, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: 200, opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                    style={{
                                        marginTop: "20px",
                                        display: "flex",
                                        flex: 1,
                                    }}>
                                    <SignUpFlow
                                        onComplete={handleCompleteSignUp}
                                        onBack={() => setShowSignUpFlow(false)}
                                    />
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    {!showSignUpFlow && (
                        <div>
                            <p style={termsStyle}>
                                I agree with{" "}
                                <a href="/terms" style={linkStyle}>
                                    Terms
                                </a>
                                ,{" "}
                                <a href="/privacy" style={linkStyle}>
                                    Privacy
                                </a>
                                , and{" "}
                                <a href="/cookies" style={linkStyle}>
                                    Cookies
                                </a>{" "}
                                policy
                            </p>
                            <div
                                style={{
                                    flex: 1,
                                    height: "1px",
                                    backgroundColor: colors.GREY_3,
                                }}
                            />
                            {showLoginForm ? (
                                <p style={footerStyle}>
                                    Don't have an account ?{" "}
                                    <span
                                        style={{
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                        }}
                                        onClick={handleShowLoginForm}>
                                        Sign Up
                                    </span>
                                </p>
                            ) : (
                                <p style={footerStyle}>
                                    Already have an account?{" "}
                                    <span
                                        style={authLinkStyle}
                                        onClick={handleShowLoginForm}>
                                        Log In
                                    </span>
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {!showSignUpFlow && (
                <div
                    style={{
                        display: "flex",
                        backgroundImage: `url(${bg})`,
                        backgroundSize: "cover",
                        borderTopRightRadius: "16px",
                        width: "50%",
                        borderBottomRightRadius: "16px",
                        alignItems: "flex-end",
                    }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingBottom: 32,
                            paddingLeft: 80,
                            paddingRight: 80,
                            gap: 10,
                        }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.20566 1.48611C8.81542 1.09724 8.18416 1.09724 7.79392 1.48611L6.35314 2.92185C6.19352 3.08091 5.98486 3.18139 5.76098 3.20702L3.74017 3.4383C3.19283 3.50094 2.79925 3.99448 2.85997 4.54203L3.08416 6.56365C3.109 6.78762 3.05746 7.01341 2.93791 7.20442L1.85877 8.92856C1.56649 9.39554 1.70695 10.011 2.17291 10.3049L3.89326 11.3901C4.08385 11.5103 4.22824 11.6914 4.30304 11.9039L4.9782 13.8226C5.16107 14.3423 5.72981 14.6162 6.25012 14.4351L8.17117 13.7667C8.38399 13.6927 8.61559 13.6927 8.82841 13.7667L10.7495 14.4351C11.2698 14.6162 11.8385 14.3423 12.0214 13.8226L12.6965 11.9039C12.7713 11.6914 12.9157 11.5103 13.1063 11.3901L14.8267 10.3049C15.2926 10.011 15.4331 9.39554 15.1408 8.92856L14.0617 7.20442C13.9421 7.01341 13.8906 6.78762 13.9154 6.56365L14.1396 4.54203C14.2003 3.99448 13.8068 3.50094 13.2594 3.4383L11.2386 3.20702C11.0147 3.18139 10.8061 3.08091 10.6464 2.92185L9.20566 1.48611ZM10.728 5.77018L7.89136 8.60618L6.23669 6.95151L5.29069 7.89685L7.89136 10.4975L11.6734 6.71552L10.728 5.77018Z"
                                fill="#4B45FF"
                            />
                        </svg>
                        <p
                            style={{
                                color: colors.WHITE,
                                fontWeight: "600",
                                fontSize: "8px",
                                fontFamily: "Noto Sans",
                                lineHeight: "11.2px",
                                textTransform: "uppercase",
                                textAlign: "left",
                            }}>
                            Commitment with 100% authentic Sneakers and Apparel
                            and lowest trading fees
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

const styles = (colors: any, getThemeColor: any) => ({
    containerStyle: {
        width: "50%",
        maxWidth: "800px",
        overflow: "hidden",
        textAlign: "center",
        padding: "32px",
        paddingTop: 72,
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    } as React.CSSProperties,
    titleStyle: {
        fontSize: "11px",
        fontWeight: "600",
        color: colors.GREY_6,
        paddingTop: 12,
        paddingBottom: 75,
    } as React.CSSProperties,
    termsStyle: {
        fontSize: "11px",
        color: colors.DARKGREY_16,
        paddingBottom: 38,
        fontWeight: "500",
        fontFamily: "Noto Sans",
        lineHeight: "13.2px",
    } as React.CSSProperties,
    footerStyle: {
        fontSize: "11px",
        marginTop: "40px",
        fontFamily: "Noto Sans",
        fontWeight: "400",
        lineHeight: "16.8px",
        color: colors.DARKGREY_16,
        textTransform: "uppercase",
    } as React.CSSProperties,
    authLinkStyle: {
        textDecoration: "none",
        color: "black",
        fontWeight: "bold",
        cursor: "pointer",
    } as React.CSSProperties,
});

/** Bold, blue, no underline */
const linkStyle: React.CSSProperties = {
    textDecoration: "none",
    color: "blue",
    fontWeight: "bold",
    cursor: "pointer",
};
