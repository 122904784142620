import { pxFetch } from "../Utils/utils";
import { FetchReturn } from "./store";
import produce from "immer";

// -------------------------------------------------------------------------------------------------------------------
// This store is a NON PERSISTED store for random variables to get the non rerender features of zustand.
// Feel free to put anything in this store that is needed that doesnt need an entire store of its own.
// Example:
// The profile page tabview, we want to pass the page data to each of the tabs without prop drilling. And even
// with prop drilling, the index of the tab is changing which causes rerenders which can be stopped by using zustand.
// -------------------------------------------------------------------------------------------------------------------

type themeOptions = "light" | "dark";
type colorObjectType = {
    light: string;
    dark: string;
};

const defaultTheme: themeOptions = "dark";

export interface IMiscState {
    // States
    loginError: string | null;
    theme: themeOptions;
    showLoginModal: Boolean;
    //////////////////////////
    // State management
    //////////////////////////
    getThemeColor: (colorObject: colorObjectType) => string;
    toggleTheme: () => void;
    toggleLoginModal: () => void;
    //////////////////////////
    // Apis
    //////////////////////////
    joinWaitlist: (email: string) => Promise<string>;
    uploadModelImage: (model: string, images: any[]) => Promise<FetchReturn>;
    getEmailSettings: (email: string) => Promise<FetchReturn>;
    saveEmailSettings: (email: string, settings: any) => Promise<FetchReturn>;
    loadFAQs: (search: string, category: string, page: number) => Promise<any>;
    sendContactEmail: (data: any) => Promise<FetchReturn>;

    //////////////////////////
    // Realtime actions
    //////////////////////////

    //////////////////////////
    // Cleanup
    //////////////////////////
    clearMiscStore: () => void;
    getAdminAnalytics: () => Promise<FetchReturn>;
}

export const miscStore = (
    set: any,
    get: () => IMiscState,
    api: any,
): IMiscState => ({
    // States
    loginError: null,
    theme: defaultTheme,
    showLoginModal: false,
    //////////////////////////
    // State management
    //////////////////////////
    getThemeColor: (colorObject: colorObjectType) => {
        const currentTheme = get().theme;
        const color = colorObject[currentTheme];
        return color;
    },
    toggleTheme: () => {
        set(
            produce((state: any) => {
                state.theme = state.theme === "light" ? "dark" : "light";
            }),
            false,
            "toggleTheme",
        );
    },

    //////////////////////////
    // Apis
    //////////////////////////
    joinWaitlist: async (email: string) => {
        try {
            const response = await fetch(
                `https://notionforms.io/api/forms/rtro-waitlist/answer`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        "6b8d7780-2b40-4269-a9f3-c5b44baadc85": email,
                    }),
                },
            );
            const data = await response.json();
            if (response.status === 200 && data.type === "success") {
                return "Amazing, you're added to the waitlist!";
            } else {
                throw data;
            }
        } catch (error: any) {
            console.error(JSON.stringify(error));
            return "Something went wrong, please try again later.";
        }
    },
    uploadModelImage: async (model: string, images: any[]) => {
        const response = await pxFetch.put(`/modelImages/${model}`, {
            images,
        });
        return response;
    },
    getEmailSettings: async (email: string) => {
        const response = await pxFetch.get(
            `/user/emailSettings?email=${email}`,
        );
        return response;
    },
    saveEmailSettings: async (email: string, settings: any) => {
        const response = await pxFetch.put(`/user/emailSettings`, {
            email,
            settings,
        });
        return response;
    },
    toggleLoginModal: () => {
        set(
            produce((state: any) => {
                state.showLoginModal = !state.showLoginModal;
            }),
            false,
            "toggleLoginModal",
        );
    },

    loadFAQs: (search: string, category: string, page: number) => {
        const response = pxFetch.get(
            `/faq?search=${search}&category=${category}&page=${page}`,
        );
        return response;
    },
    sendContactEmail: (data: any) => {
        const response = pxFetch.post(`/support/contact`, data);
        return response;
    },

    //////////////////////////
    // Realtime actions
    //////////////////////////

    //////////////////////////
    // Cleanup
    //////////////////////////
    clearMiscStore: () => {
        set(
            () => ({
                loginError: null,
                showLoginModal: false,
            }),
            false,
            "Clearing store",
        );
    },
    getAdminAnalytics: async () => {
        const response = await pxFetch.get(`/admin/analytics`);
        return response;
    },
});

export default miscStore;
