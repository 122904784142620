import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import PxButton from "./PxButton";
import {
    RiArrowLeftLine,
    RiArrowRightLine,
    RiChat1Fill,
    RiCheckboxCircleLine,
    RiCheckFill,
    RiCheckLine,
    RiStoreLine,
    RiUserLine,
    RiUserSettingsLine,
} from "react-icons/ri";
import { colors } from "../Utils/colors";
import TradeBlockSVG from "./../images/tradeblock";
import TradeBlockSVGSuccess from "../images/tradeblockSuccess";
import PxInput from "./PxInput";
import { useAuth } from "../Hooks/useAuth";
import PasswordValidator from "password-validator";
interface SignUpFlowProps {
    onComplete?: () => void; // optional callback after the final step
    onBack?: () => void; // optional callback for "Log In" link in Step 0
}

export const checkEmailValidity = (email: string) => {
    // Regular expression for a basic email validation
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

    // Test the email against the regex
    return emailRegex.test(email) ? "" : "Please enter a valid email address";
};

const type = "text";
export default function SignUpFlow({ onComplete, onBack }: SignUpFlowProps) {
    const [stepIndex, setStepIndex] = useState(0);

    // Shared state across steps
    const [accountType, setAccountType] = useState<"user" | "store" | null>(
        null,
    );
    const { handleEmailPassSignUp, checkIfEmailExists } = useAuth();
    const [username, setUsername] = useState("");
    const [tradeblockUser, setTradeblockUser] = useState("");
    const [emailOrPhone, setEmailOrPhone] = useState("");
    const [password, setPassword] = useState("");
    const [referralCode, setReferralCode] = useState("");
    const [emailInUse, setEmailInUse] = useState(false);
    // For simulating the loading step
    const [isLoading, setIsLoading] = useState(false);

    const passwordSchema = new PasswordValidator()
        .is()
        .min(8, "Password must have a minimum of 8 characters")
        .has()
        .uppercase(1, "Password must have at least 1 uppercase letter")
        .has()
        .digits(1, "Password must have at least 1 digit")
        .has()
        .symbols(1, "Password must have at least 1 symbol");

    const [passwordError, setPasswordError] = useState<string | null>(null); // State for validation error

    const validatePassword = (password: string) => {
        const validationErrors = passwordSchema.validate(password, {
            list: true,
        });
        if (Array.isArray(validationErrors) && validationErrors.length > 0) {
            const errorMessage = validationErrors
                .map((error: any) => {
                    switch (error) {
                        case "min":
                            return "Password must have a minimum of 8 characters";
                        case "uppercase":
                            return "Password must have at least 1 uppercase letter";
                        case "digits":
                            return "Password must have at least 1 digit";
                        case "symbols":
                            return "Password must have at least 1 symbol";
                        default:
                            return "Invalid password";
                    }
                })
                .join(", ");
            setPasswordError(errorMessage);
            return false;
        }
        setPasswordError(null);
        return true;
    };

    const goToStep = (index: number) => {
        setStepIndex(index);
    };

    const createAccount = async () => {
        // Simulate loading
        setIsLoading(true);
        const response = await handleEmailPassSignUp(emailOrPhone, password);
        if (response.code !== 200) {
            alert(response.errorMessage);
            setIsLoading(false);
            return;
        } else {
            goToStep(8);
        }
        // Move to step 8 (loading)
        setTimeout(() => {
            setIsLoading(false);
        }, 4000);
        // goToStep(8);
    };

    const handleEmailNextStep = async () => {
        if (!emailOrPhone.trim()) {
            alert("Please enter your email or phone.");
            return;
        }
        setIsLoading(true);
        const exists = await checkIfEmailExists(emailOrPhone);

        setIsLoading(false);
        if (exists) {
            setEmailInUse(true);
            return;
        }

        goToStep(6);
    };
    // (A) Step 0: Select Account Type
    const Step0 = (
        <div
            key="step0"
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: "space-between",
            }}>
            <h3
                style={{
                    fontSize: 24,
                    fontWeight: 700,
                    paddingBottom: 30,
                }}>
                Select your Account type
            </h3>
            <div
                style={{
                    display: "flex",
                    gap: 16,
                    justifyContent: "center",
                    marginBottom: 20,
                }}>
                {/* USER CARD */}
                <div
                    onClick={() => setAccountType("user")}
                    style={{
                        cursor: "pointer",
                        border:
                            accountType === "user"
                                ? "2px solid blue"
                                : "1px solid #ccc",
                        borderRadius: 8,
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        fontSize: 18,
                        fontWeight: 700,
                        padding: 79,
                        width: 233,
                        height: 233,
                        gap: 12,
                    }}>
                    <RiUserLine size={40} className="my-icon" />
                    User
                </div>

                {/* STORE CARD */}
                <div
                    onClick={() => setAccountType("store")}
                    style={{
                        cursor: "pointer",
                        border:
                            accountType === "store"
                                ? "2px solid blue"
                                : "1px solid #ccc",
                        borderRadius: 8,
                        padding: 79,
                        width: 233,
                        height: 233,
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        fontSize: 18,
                        fontWeight: 700,
                        gap: 12,
                    }}>
                    <RiStoreLine size={40} className="my-icon" />
                    Store
                </div>
            </div>

            <div>
                <PxButton
                    label="Continue"
                    icon={<RiArrowRightLine size={20} />}
                    onClick={() => {
                        if (accountType) {
                            goToStep(1);
                        } else {
                            alert("Please select an account type first.");
                        }
                    }}
                />
            </div>

            <div>
                <div
                    style={{
                        height: "1px",
                        backgroundColor: colors.GREY_3,
                    }}
                />

                <p
                    style={{
                        fontSize: "11px",
                        marginTop: "40px",
                        fontFamily: "Noto Sans",
                        fontWeight: 400,
                        lineHeight: "16.8px",
                        color: colors.DARKGREY_16,
                        textTransform: "uppercase",
                    }}>
                    Already have an account?{" "}
                    <span
                        style={{
                            textDecoration: "none",
                            color: "black",
                            fontWeight: "bold",
                            cursor: "pointer",
                        }}
                        onClick={onBack}>
                        Log In
                    </span>
                </p>
            </div>
        </div>
    );

    // (B) Step 1: Create a Username
    // Two buttons: "Import from tradeblock" => stepIndex(2)
    //              "Continue" => stepIndex(5)
    const Step1 = (
        <div
            key="step1"
            style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
                height: "100%",
                justifyContent: "space-between",
            }}>
            <h3 style={{ fontSize: 24, fontWeight: 700, paddingBottom: 30 }}>
                Create a Username
            </h3>
            <PxInput
                label="Username"
                type={type}
                placeholder="Enter a username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />

            <div style={{ width: "100%" }}>
                {/* <PxButton
                    label="Import from tradeblock"
                    icon={<RiUserSettingsLine size={20} />}
                    onClick={() => {
                        if (!username.trim()) {
                            alert("Please enter a username first.");
                            return;
                        }
                        // Go to Tradeblock flow steps
                        goToStep(2);
                    }}
                    variant="outline"
                /> */}
                <PxButton
                    label="Continue"
                    icon={<RiArrowRightLine size={20} />}
                    onClick={() => {
                        if (!username.trim()) {
                            alert("Please enter a username first.");
                            return;
                        }
                        // Go to normal flow steps
                        goToStep(5);
                    }}
                />
            </div>
        </div>
    );

    // (C) Step 2: "What's the username you're using at Tradeblock?"
    const Step2 = (
        <div
            key="step2"
            style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
                height: "100%",
                justifyContent: "space-between",
            }}>
            <h3 style={{ fontSize: 24, fontWeight: 700, paddingBottom: 30 }}>
                What's the username you're using at Tradeblock?
            </h3>
            <PxInput
                label="Username"
                type={type}
                placeholder="Enter your Tradeblock username"
                value={tradeblockUser}
                onChange={(e) => setTradeblockUser(e.target.value)}
            />

            <PxButton
                label="Continue"
                icon={<RiArrowRightLine size={20} />}
                onClick={() => {
                    if (!tradeblockUser.trim()) {
                        alert("Please enter your Tradeblock username.");
                        return;
                    }
                    // Simulate loading
                    setIsLoading(true);
                    // Move to step 3 (loading)
                    goToStep(3);
                }}
            />
        </div>
    );

    // (D) Step 3: Loading state
    // We'll automatically "finish loading" after 1.5s, then go to Step 4.
    if (stepIndex === 3 && isLoading) {
        setTimeout(() => {
            setIsLoading(false);
            goToStep(4);
        }, 1500);
    }
    const Step3 = (
        <div
            key="step3"
            style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
            }}>
            <TradeBlockSVG />
            <p
                style={{
                    paddingTop: 30,
                    textAlign: "center",
                    fontSize: 24,
                    fontWeight: "700",
                }}>
                Syncing your Tradeblock account...
            </p>
        </div>
    );

    // (E) Step 4: Success screen with "Ok" button => calls onComplete
    const Step4 = (
        <div
            key="step4"
            style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: 300,
                flex: 1,
            }}>
            <TradeBlockSVGSuccess />

            <p
                style={{
                    paddingTop: 30,
                    textAlign: "center",
                    fontSize: 24,
                    fontWeight: "700",
                }}>
                Success!
            </p>

            <PxButton
                label="Continue"
                icon={<RiArrowRightLine size={20} />}
                onClick={() => {
                    goToStep(5);
                }}
            />
        </div>
    );

    // (F) Step 5: "What is your e-mail or phone?"
    const Step5 = (
        <div
            key="step5"
            style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
                height: "100%",
                justifyContent: "space-between",
            }}>
            <h3 style={{ fontSize: 24, fontWeight: 700, paddingBottom: 30 }}>
                What is your e-mail?
            </h3>

            <PxInput
                label="Email"
                type={type}
                placeholder="Enter email"
                value={emailOrPhone}
                onChange={(e) => {
                    setEmailOrPhone(e.target.value);
                    if (emailInUse) {
                        setEmailInUse(false);
                    }
                }}
                isError={
                    emailInUse ||
                    (emailOrPhone ? !!checkEmailValidity(emailOrPhone) : false)
                }
                errorMessage={
                    emailInUse
                        ? "Email already in use"
                        : emailOrPhone && checkEmailValidity(emailOrPhone)
                }
            />

            <PxButton
                label="Continue"
                disabled={
                    emailInUse ||
                    (emailOrPhone ? !!checkEmailValidity(emailOrPhone) : true)
                }
                loading={isLoading}
                icon={<RiArrowRightLine size={20} />}
                onClick={handleEmailNextStep}
            />
        </div>
    );

    // (G) Step 6: "Type a password"
    const Step6 = (
        <div
            key="step6"
            style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
                height: "100%",
                justifyContent: "space-between",
            }}>
            <h3 style={{ fontSize: 24, fontWeight: 700, paddingBottom: 30 }}>
                Type a Password
            </h3>

            <PxInput
                label="password"
                type={"password"}
                placeholder="Enter a password"
                value={password}
                onChange={(e) => {
                    setPassword(e.target.value);
                    validatePassword(e.target.value);
                }}
                isError={!!passwordError}
                errorMessage={passwordError ?? ""}
            />

            <PxButton
                label="Continue"
                disabled={!!passwordError || !password}
                icon={<RiArrowRightLine size={20} />}
                onClick={() => {
                    if (!password.trim()) {
                        alert("Please enter a password.");
                        return;
                    }
                    goToStep(7);
                }}
            />
        </div>
    );

    // (H) Step 7: "Referral code?" with input and 2 buttons (continue / skip)
    // Both just call onComplete. You could do skip = onComplete, continue = onComplete or store the code.
    const Step7 = (
        <div
            key="step7"
            style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
                height: "100%",
                justifyContent: "space-between",
            }}>
            <h3 style={{ fontSize: 24, fontWeight: 700, paddingBottom: 30 }}>
                Do you have a referral code?
            </h3>

            <PxInput
                label="Referral code"
                type={type}
                placeholder="Referral code (optional)"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
            />

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    gap: 10,
                    flexDirection: "column",
                }}>
                <PxButton
                    label="Skip"
                    variant="outline"
                    loading={isLoading}
                    onClick={() => {
                        createAccount();
                        // goToStep(8);
                    }}
                />
                <PxButton
                    label="Continue"
                    icon={<RiArrowRightLine size={20} />}
                    loading={isLoading}
                    onClick={() => {
                        createAccount();
                        // goToStep(8);
                    }}
                />
            </div>
        </div>
    );

    const Step8 = (
        <div
            key="step8"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                height: "100%",
            }}>
            <div
                style={{
                    display: "flex",
                    border: `3px solid ${colors.BLUE}`,

                    borderColor: colors.BLUE,
                    padding: 10,
                    borderRadius: 100,
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 24,
                }}>
                <RiCheckFill size={50} color={colors.BLUE} />
            </div>
            <h3 style={{ fontSize: 24, fontWeight: 700, paddingBottom: 10 }}>
                Welcome to RTRO!
            </h3>
            <p
                style={{
                    fontSize: 16,
                    fontWeight: 400,
                    textAlign: "center",
                    color: "#6C757D",
                    marginBottom: 30,
                }}>
                Your account has been successfully created. Get ready to
                explore!
            </p>
            <PxButton
                label="Get Started"
                icon={<RiArrowRightLine size={20} />}
                onClick={() => {
                    onComplete?.(); // Call the `onComplete` callback when "Get Started" is clicked
                }}
            />
        </div>
    );

    const Step9 = (
        <div
            key="step9"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                height: "100%",
            }}>
            <div
                style={{
                    display: "flex",
                    border: `3px solid ${colors.RED}`,
                    borderRadius: 100,
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 10,
                    marginBottom: 24,
                }}>
                <RiChat1Fill size={50} color={colors.RED} />
            </div>
            <h3 style={{ fontSize: 24, fontWeight: 700, paddingBottom: 10 }}>
                Oops! Something went wrong.
            </h3>
            <p
                style={{
                    fontSize: 16,
                    fontWeight: 400,
                    textAlign: "center",
                    color: "#6C757D",
                    marginBottom: 30,
                }}>
                We couldn't import your Tradeblock account. Please try again or
                skip this step.
            </p>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 16,
                    width: "100%",
                    justifyContent: "center",
                }}>
                <PxButton
                    label="Retry"
                    onClick={() => {
                        goToStep(2); // Go back to the Tradeblock username input step
                    }}
                />
                <PxButton
                    label="Skip"
                    variant="outline"
                    onClick={() => {
                        goToStep(5); // Skip to the email/phone step
                    }}
                />
            </div>
        </div>
    );

    // Build an array of all possible steps (0..7).
    // We branch internally by jumping from step 1 -> step 2 or step 5, etc.
    const steps = [
        Step0, // 0
        Step1, // 1
        Step2, // 2 (Tradeblock username input)
        Step3, // 3 (loading)
        Step4, // 4 (success => OK => onComplete)
        Step5, // 5 (email/phone)
        Step6, // 6 (password)
        Step7, // 7 (referral code => skip/continue => onComplete)
        Step8, // 8 (success => Get Started => onComplete)
        Step9, // 9 (error => Retry/Skip)
    ];

    return (
        <div
            style={{
                display: "flex",
                flex: 1,
            }}>
            {stepIndex !== 8 && (
                <div
                    onClick={() => {
                        if (stepIndex === 0) {
                            onBack?.();
                        } else {
                            goToStep(stepIndex - 1);
                        }
                    }}
                    style={{
                        position: "absolute",
                        left: 16,
                        top: 16,
                    }}>
                    <RiArrowLeftLine size={24} className="my-icon" />
                </div>
            )}
            <AnimatePresence mode="wait">
                <motion.div
                    key={stepIndex}
                    initial={{ x: 200, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -200, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    style={{
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        height: "100%",
                    }}>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                        }}>
                        {steps[stepIndex]}
                    </div>
                </motion.div>
            </AnimatePresence>
        </div>
    );
}
