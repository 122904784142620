import React, { useEffect, useState } from "react";
import { useMiscStore } from "../../../state/store";
import { NetworkCode } from "../../../Utils/network";
import { Card, Col, Row } from "antd";
import { colors } from "../../../Utils/colors";

const AnalyticsPage = () => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const [analytics, setAnalytics] = useState<any>(null);
    const getAdminAnalytics = useMiscStore((state) => state.getAdminAnalytics);

    const fetchAnalytics = async () => {
        const response = await getAdminAnalytics();
        if (response?.status === NetworkCode.OK) {
            // Set the analytics
            setAnalytics(response.data);
        }
    };

    useEffect(() => {
        fetchAnalytics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{}}>
            <h1
                style={{
                    color: getThemeColor({
                        light: colors.DARKGREY_16,
                        dark: colors.GREY_0,
                    }),
                }}>
                Analytics
            </h1>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={6}>
                    <Card title="Total users" color="#3f51b5">
                        {analytics?.totalUsers?.totalUsers}
                    </Card>
                </Col>
                <Col className="gutter-row" span={6}>
                    <Card title="Sign Ups - Last 24 hours" color="#3f51b5">
                        {analytics?.totalSignupsPast24Hours
                            ?.totalSignupsPast24Hours ?? 0}
                    </Card>
                </Col>
                <Col className="gutter-row" span={6}>
                    <Card title="Sign Ups - Last 7 days" color="#3f51b5">
                        {analytics?.totalSignupsPast7Days
                            ?.totalSignupsPast7Days ?? 0}
                    </Card>
                </Col>
                <Col className="gutter-row" span={6}>
                    <Card title="Total Trades" color="#9c27b0">
                        {analytics?.totalTrades?.totalCount}
                    </Card>
                </Col>
                <Col className="gutter-row" span={6}>
                    {analytics?.totalTrades?.statuses.map((status: any) => (
                        <Card
                            key={status.status}
                            title={`${status.status} Trades`}
                            color={"#9c27b0"}>
                            {status.count}
                        </Card>
                    ))}
                </Col>
                <Col className="gutter-row" span={6}>
                    <Card title="Total Inventory Items" color="#2196f3">
                        {analytics?.totalInventoryItems?.totalClosetItems}
                    </Card>
                </Col>
                <Col className="gutter-row" span={6}>
                    <Card title="Total Wishlist Items" color="#2196f3">
                        {analytics?.totalWishlistItems?.totalWishlistItems}
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default AnalyticsPage;
