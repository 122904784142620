const TradeBlockSVGSuccess = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="312"
        height="228"
        viewBox="0 0 353 238"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M285 109C285 168.095 237.094 216 178 216C118.906 216 71 168.095 71 109"
            stroke="#E9ECEF"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M285 109C285 49.9057 237.094 2.00021 178 2.00021C118.906 2.00021 71 49.9057 71 109"
            stroke="#E9ECEF"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M244 109.496C244 145.673 214.451 175 178 175C141.549 175 112 145.673 112 109.496"
            stroke="#E9ECEF"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M244 109.496C244 72.7716 214.451 43.0002 178 43.0002C141.549 43.0002 112 72.7716 112 109.496"
            stroke="#E9ECEF"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect
            x="223.988"
            y="48.4724"
            width="88"
            height="88"
            rx="18.2069"
            fill="#060606"
        />
        <path
            d="M251.488 111.722C254.526 111.722 256.988 109.26 256.988 106.222C256.988 103.185 254.526 100.722 251.488 100.722V111.722Z"
            fill="#FEFEFE"
        />
        <path
            d="M251.488 89.7224V78.7224L284.488 111.722H273.488L251.488 89.7224Z"
            fill="#4B45FF"
        />
        <path
            d="M257.468 73.7015L277.135 93.3682C278.315 91.2756 278.989 88.8589 278.989 86.2849C278.989 78.3113 272.525 71.8474 264.551 71.8474C261.977 71.8474 259.56 72.521 257.468 73.7015Z"
            fill="#FEFEFE"
        />
        <g filter="url(#filter0_d_204_26009)">
            <rect
                x="47.5283"
                y="87.0676"
                width="82.0005"
                height="82"
                rx="17.5715"
                fill="#FEFEFE"
            />
            <g clip-path="url(#clip0_204_26009)">
                <path
                    d="M88.528 131.735V135.401C85.6106 135.401 82.8127 136.56 80.7498 138.623C78.6869 140.686 77.528 143.484 77.528 146.401H73.8613C73.8613 142.512 75.4066 138.781 78.1571 136.031C80.9076 133.28 84.6382 131.735 88.528 131.735ZM88.528 129.901C82.4505 129.901 77.528 124.979 77.528 118.901C77.528 112.824 82.4505 107.901 88.528 107.901C94.6055 107.901 99.528 112.824 99.528 118.901C99.528 124.979 94.6055 129.901 88.528 129.901ZM88.528 126.235C92.5797 126.235 95.8613 122.953 95.8613 118.901C95.8613 114.85 92.5797 111.568 88.528 111.568C84.4763 111.568 81.1947 114.85 81.1947 118.901C81.1947 122.953 84.4763 126.235 88.528 126.235ZM93.2855 140.557C93.053 139.579 93.053 138.559 93.2855 137.581L91.4668 136.531L93.3002 133.355L95.1188 134.406C95.8488 133.715 96.7312 133.205 97.6947 132.917V130.818H101.361V132.917C102.337 133.207 103.217 133.724 103.937 134.406L105.756 133.355L107.589 136.531L105.77 137.581C106.003 138.559 106.003 139.577 105.77 140.555L107.589 141.605L105.756 144.781L103.937 143.73C103.207 144.422 102.325 144.932 101.361 145.219V147.318H97.6947V145.219C96.7312 144.932 95.8488 144.422 95.1188 143.73L93.3002 144.781L91.4668 141.605L93.2855 140.557ZM99.528 141.818C100.257 141.818 100.957 141.528 101.473 141.013C101.988 140.497 102.278 139.797 102.278 139.068C102.278 138.339 101.988 137.639 101.473 137.124C100.957 136.608 100.257 136.318 99.528 136.318C98.7987 136.318 98.0992 136.608 97.5835 137.124C97.0677 137.639 96.778 138.339 96.778 139.068C96.778 139.797 97.0677 140.497 97.5835 141.013C98.0992 141.528 98.7987 141.818 99.528 141.818Z"
                    fill="#ADB5BD"
                />
            </g>
        </g>
        <g clip-path="url(#clip1_204_26009)">
            <path
                d="M174.72 113.747L188.508 99.9575L190.63 102.078L174.72 117.989L165.174 108.443L167.295 106.322L174.72 113.747Z"
                fill="#4B45FF"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_204_26009"
                x="0.67104"
                y="51.9246"
                width="175.715"
                height="175.715"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="11.7143" />
                <feGaussianBlur stdDeviation="23.4286" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_204_26009"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_204_26009"
                    result="shape"
                />
            </filter>
            <clipPath id="clip0_204_26009">
                <rect
                    width="44"
                    height="44"
                    fill="white"
                    transform="translate(66.5283 106.068)"
                />
            </clipPath>
            <clipPath id="clip1_204_26009">
                <rect
                    width="36"
                    height="36"
                    fill="white"
                    transform="translate(159.719 90.989)"
                />
            </clipPath>
        </defs>
    </svg>
);

export default TradeBlockSVGSuccess;
