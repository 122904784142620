import React from "react";
import { motion } from "framer-motion";
import { colors } from "../Utils/colors";

interface PxButtonProps {
    label: string;
    onClick?: () => void;
    icon?: React.ReactElement;
    variant?: "primary" | "outline";
    style?: React.CSSProperties;
    loading?: boolean;
    disabled?: boolean;
}

export default function PxButton({
    label,
    onClick,
    icon,
    variant = "primary",
    style = {},
    loading = false,
    disabled = false,
}: PxButtonProps) {
    const isDisabled = loading || disabled;

    const primaryStyle: React.CSSProperties = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "13px 24px",
        borderRadius: 24,
        fontSize: 12,
        fontWeight: 600,
        marginBottom: 10,
        cursor: isDisabled ? "not-allowed" : "pointer",
        backgroundColor: isDisabled ? colors.GREY_3 : colors.DARKGREY_16,
        color: isDisabled ? colors.GREY_7 : "#fff",
        fontFamily: "Noto Sans",
        letterSpacing: "1.92px",
        border: "none",
        opacity: isDisabled ? 0.6 : 1,
    };

    const outlineStyle: React.CSSProperties = {
        ...primaryStyle,
        backgroundColor: "#fff",
        color: isDisabled ? colors.GREY_7 : "#212121",
        border: `1px solid ${isDisabled ? colors.GREY_5 : "#ccc"}`,
        fontWeight: 500,
        borderRadius: 25,
    };

    const mergedStyle: React.CSSProperties = {
        ...(variant === "primary" ? primaryStyle : outlineStyle),
        ...style,
    };

    return (
        <motion.button
            style={mergedStyle}
            onClick={!isDisabled ? onClick : undefined}
            whileTap={!isDisabled ? { scale: 0.97 } : undefined}
            whileHover={!isDisabled ? { scale: 1.01 } : undefined}
            disabled={isDisabled}>
            {loading ? (
                <span
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: 12,
                    }}>
                    <span
                        className="loader"
                        style={{
                            width: 16,
                            height: 16,
                            border: "2px solid #fff",
                            borderTop: "2px solid transparent",
                            borderRadius: "50%",
                            animation: "spin 1s linear infinite",
                            marginRight: 8,
                        }}></span>
                    {label}
                </span>
            ) : (
                <>
                    <span
                        style={{
                            fontFamily: "Noto Sans",
                            fontWeight: 600,
                            fontSize: 12,
                            textTransform: "uppercase",
                        }}>
                        {label}
                    </span>
                    {icon && <span style={{ marginLeft: 10 }}>{icon}</span>}
                </>
            )}
        </motion.button>
    );
}

const styles = `
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`;

// Inject styles into the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
